export const BIGCOMMERCE_API_URL =
  process.env.BIGCOMMERCE_API_URL ?? "https://api.bigcommerce.com";
export const BIGCOMMERCE_CANONICAL_STORE_DOMAIN =
  process.env.BIGCOMMERCE_CANONICAL_STORE_DOMAIN ?? "mybigcommerce.com";
export const BIGCOMMERCE_GRAPHQL_API_ENDPOINT = `${BIGCOMMERCE_CANONICAL_STORE_DOMAIN}/graphql`;

export const HIDDEN_PRODUCT_TAG = "nextjs-frontend-hidden";

export const CHECKOUT_DOMAIN = process.env.CHECKOUT_DOMAIN;
export const STORE_DOMAIN = process.env.STORE_DOMAIN;

export const BigCommerceSortKeys = {
  A_TO_Z: "A_TO_Z",
  BEST_REVIEWED: "BEST_REVIEWED",
  BEST_SELLING: "BEST_SELLING",
  RELEVANCE: "RELEVANCE",
  FEATURED: "FEATURED",
  HIGHEST_PRICE: "HIGHEST_PRICE",
  LOWEST_PRICE: "LOWEST_PRICE",
  NEWEST: "NEWEST",
  Z_TO_A: "Z_TO_A",
};

export const VercelSortKeys = {
  RELEVANCE: "RELEVANCE",
  BEST_SELLING: "BEST_SELLING",
  CREATED_AT: "CREATED_AT",
  PRICE: "PRICE",
  FEATURED: "FEATURED",
};

export const vercelToBigCommerceSortKeys = {
  RELEVANCE: "RELEVANCE",
  BEST_SELLING: "BEST_SELLING",
  CREATED_AT: "NEWEST",
  PRICE: "LOWEST_PRICE",
  PRICE_ON_REVERSE: "HIGHEST_PRICE",
  FEATURED: "FEATURED",
};

export const defaultSort = {
  title: "RELEVANCE",
  slug: null,
  sortKey: "RELEVANCE",
  reverse: false,
};

export const sorting = [
  defaultSort,
  {
    title: "TRENDING",
    slug: "trending-desc",
    sortKey: "BEST_SELLING",
    reverse: false,
  }, // asc
  {
    title: "NEWEST",
    slug: "latest-desc",
    sortKey: "CREATED_AT",
    reverse: true,
  },
  {
    title: "LOWEST PRICE",
    slug: "price-asc",
    sortKey: "PRICE",
    reverse: false,
  }, // asc
  {
    title: "HIGHEST PRICE",
    slug: "price-desc",
    sortKey: "PRICE",
    reverse: true,
  },
  {
    title: "FEATURED",
    slug: "featured",
    sortKey: "FEATURED",
    reverse: false,
  },
];

export const TAGS = {
  collections: "collections",
  products: "products",
};
