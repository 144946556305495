// pages/_error.tsx
"use client";

import NotFound404 from "../components/Organisms/NotFound404";

function Error(err) {
  return <NotFound404 />;
}

Error.getInitialProps = ({ res, err }) => {
  if (typeof window != "undefined") {
    err && window.newrelic.noticeError(err);
  }

  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default Error;
