export const fontSize = {
  "8px": "text-[8px]",
  "10px": "text-[11px]",
  "12px": "text-xs",
  "14px": "text-sm",
  xs: "2xl:text-xs", //17
  sm: "2xl:text-sm", //18
  base: "2xl:text-base", //21
  lg: "2xl:text-lg", //24
  xl: "2xl:text-xl", //26
  "2xl": "2xl:text-2xl", //32
  "28px": "2xl:text-[28px]",
  "3xl": "2xl:text-3xl", //50
  "32px": "2xl:text-[32px]", //32
  "4xl": "2xl:text-4xl", //50
  "40px": "2xl:text-[40px]",
  "6xl": "2xl:text-6xl", //60
  "36px": "2xl:text-[36px]", //36
  "40px": "2xl:text-[40px]", //40
  "100px": "2xl:text-[100px]",
};

export const fontSizeLg = {
  "8px": "text-[8px]",
  "10px": "md:text-sm",
  "12px": "md:text-base",
  "14px": "md:text-base",
  xs: "lg:text-xs", //17
  sm: "lg:text-sm", //18
  base: "lg:text-base", //21
  lg: "lg:text-lg", //24
  xl: "lg:text-xl", //26
  "2xl": "lg:text-2xl", //32
  "28px": "lg:text-[28px]",
  "3xl": "lg:text-3xl", //50
  "32px": "lg:text-[32px]", //32
  "4xl": "lg:text-4xl", //50
  "6xl": "lg:text-6xl", //60
  "36px": "md:text-[36px]", //40
  "40px": "lg:text-[40px]", //40
  "100px": "lg:text-[100px]",
};

export const fontSizeSm = {
  "8px": "text-[8px]",
  "10px": "md:text-sm",
  "12px": "md:text-base",
  "14px": "md:text-base",
  xs: "text-xs", //17
  sm: "text-xs", //18
  base: "text-sm", //21
  lg: "text-base", //24
  xl: "text-base", //26
  "2xl": "text-xl", //24
  "28px": "text-xl",
  "3xl": "text-2xl", //26
  "32px": "text-base", //32
  "4xl": "text-2xl", //26
  "6xl": "text-3xl", //30
  "36px": "text-xl",
  "40px": "text-[28px]",
  "100px": "text-6xl",
};
export const fontType = {
  normal: "font-normal",
  thin: "font-thin",
  extralight: "font-extralight",
  light: "font-light",
  medium: "font-medium",
  semibold: "font-semibold",
  bold: "font-bold",
  extrabold: "font-extrabold",
};

export const textAlign = {
  left: "text-left",
  right: "text-right",
  center: "text-center",
};

export const textDecoration = {
  underline: "underline",
  overline: "overline",
  lineThrough: "line-through",
  noUnderLine: "no-underline",
};

export const textColors = {
  New_Primary_Default: "text-New_Primary_Default",
  Primary_Default: "text-Primary_Default",
  Text_Gray: "text-Text_Gray",
  Text_Gray2: "text-Text_Gray2",
  Text_Grey3: "text-Text_Grey3",
  Stroke_Grey: "text-Stroke_Grey",
  White: "text-white",
  inherit: "text-inherit",
  red: "text-Error_Default",
  green: "text-Selected_State_Color",
};

export const fontStyleArr = {
  italic: "italic",
};
