export const LoadingWhiteSpinner = "/Assets/loading.gif";
export const WhatsAppIcon = "/Assets/whatsApp.svg";
export const ClipboardIcon = "/Assets/Clipboard.svg";
export const PinkBoxIcon = "/pinkBox.webp";
export const WearThatLogo = "/Assets/WearThatLogo.svg";
export const NewMenuIcon = "/Assets/sideDrawericon.svg";
export const CloseIcon = "/Assets/closeIcon.svg";
export const infoIcon = "/Assets/infoIcon.svg";
export const VideoIcon = "/Assets/VideoIcon.svg";
export const RightArrowIcon = "/Assets/RightArrow.svg";
export const SuccessTickPink = "/Assets/successTickPink.svg";
export const FailedIcon = "/Assets/failedIcon.svg";
export const SearchIcon = "/Assets/Search.svg";
export const InstagramIcon = "/Assets/instagram.svg";
export const PinterestIcon = "/Assets/pinterest.svg";
export const WTLogoEnglish = "/Assets/WTLogoEnglish.svg";
export const VisaSmallIcon = "/Assets/VisaIcon.svg";
export const MasterSmallIcon = "/Assets/MasterIcon.svg";
export const DiscoverCardIcon = "/Assets/DiscoverIcon.svg";
export const DinersCardIcon = "/Assets/DinersInternationalIcon.svg";
export const AmericanExpressIcon = "/Assets/AmericanExpressIcon.svg";
export const JCBCardIcon = "/Assets/JCBIcon.svg";
export const DropdownArrowDown = "/Assets/PinkDownArrow.svg";
export const DropdownArrowUp = "/Assets/PinkUpArrow.svg";
export const TertiaryRightArrow = "/Assets/TertiaryRightArrow.svg";
export const ConfirmationIcon = "/Assets/confirmationIcon.svg";
export const BlackSimpleArrow = "/Assets/RightBlackArrow.svg";
export const WearthatLoading = "/Assets/WearthatLoading.gif";
export const WhiteRightArrow = "/Assets/whiteRightArrow.svg";
export const BlackPlusIcon = "/Assets/blackPlusIcon.svg";
export const MinusIcon = "/Assets/minusIcon.svg";
export const Switch_404 = "/Assets/404-switch.webp";
export const StepOne = "/Assets/step1.svg";
export const StepTwo = "/Assets/step2.svg";
export const EditIcon = "/Assets/Edit-Icon.svg";
export const SmallPlus = "/Assets/Plus14x14.svg";
export const SmallMinus = "/Assets/Minus14x14.svg";
export const BlackBin = "/Assets/BlackBin.svg";
export const MobileSizeChart = "/Assets/mobileSizeChart.svg";
export const SizeChart = "/Assets/sizeChart.svg";
export const TickInCircleGreen = "/Assets/TickInCircleGreen.svg";
export const CrossInCircleRed = "/Assets/CrossInCircleRed.svg";
export const EcommCart = "/Assets/cart_white_24x24.svg";
export const EcommCartBlack = "/Assets/black_cart_24x24.svg";
export const WhiteChevronDown = "/Assets/white_chevron_down.svg";
export const BlackChevronDown = "/Assets/BlackChevronDown.svg";
export const FunnelIcon = "/Assets/Funnel.svg";
export const SearchIconBlack = "/Assets/SearchIcon.svg";
export const SearchIconGrey = "/Assets/SearchIconGrey.svg";
export const WhiteSearchIcon = "/Assets/WhiteSearchIcon.svg";
export const BlackCross_24x24 = "/Assets/BlackCross_24x24.svg";
export const ArrowDownPlaneEnds = "/Assets/arrowDownPlaneEnds.svg";
export const ShopLandingBanner = "/Assets/CapsuleComingDesktop.webp";
export const BambuserPlaceholder = "/Assets/bambuserPlaceholder.webp";
export const BambuserPlaceholderMobile = "/Assets/bambusarMobile.webp";
export const HeaderArrow = "/Assets/headerArrow.svg";
export const SerenaHeroMobile = "/Assets/SerenaLandingPageHeroMobile.webp";
export const SerenaHeroDesktop = "/Assets/SerenaLandingPageHeroDesktop.webp";
export const BasmaHeroDesktop = "/Assets/BasmaHeroDesktop.webp";
export const BasmaHeroMobile = "/Assets/BasmaHeroMobile.webp";
export const wearthatXBasma = "/Assets/wearthatXBasmaLogo.svg";
export const WearthatXTamaraWhite = "/Assets/WearThatTamaraFarra.svg";
export const WearthatXTamaraBlack = "/Assets/WearThatTamaraFarraBlack.svg";
export const WearthatXJessieWhite = "/Assets/WearThatJessie.svg";
export const WearthatJessieWhiteMobile =
  "/Assets/WearthatJessieWhiteMobile.svg";
export const WearthatXJessieBlack = "/Assets/WearThatJessieBlack.svg";
export const Collage = "/Assets/Collage.webp";
export const RamadanNote = "/Assets/RamadanGlimpseNote.svg";
export const RamadanNoteMobile = "/Assets/RamadanGlimpseNoteMobile.svg";
export const BasmaComing = "/Assets/BasmaComingDesktop.png";
export const LiveEventBannerImage = "/Assets/LiveEventBannerImage.jpg";
export const BasmaHamzeLiveEvent = "/Assets/BasmaHamzeLiveEvent.jpg";
export const JessieLiveEvent = "/Assets/JessieLiveEvent.png";
export const SerenaBySeaLiveEvent = "/Assets/SerenaBySeaLiveEvent.webp";
export const MobileHeroBannerImage = "/Assets/MobileHeroBanner.webp";
export const HeroBannerImage = "/Assets/HeroBanner.webp";
export const ModestEditImage = "/Assets/modestEdit.webp";
export const ModestEditMobileImage = "/Assets/modestEditMobile.webp";
export const MonoEditImage = "/Assets/monoEdit.webp";
export const MonoEditMobileImage = "/Assets/monoEditMobile.webp";
export const CapsuleEditImage = "/Assets/capsuleEdit.webp";
export const CapsuleEditMobileImage = "/Assets/capsuleEditMobile.webp";
export const TamaraEditImage = "/Assets/TamaraEditImage.webp";
export const TamaraEditMobileImage = "/Assets/TamaraEditMobileImage.webp";
export const SummerEditImage = "/Assets/SummerEditImage.webp";
export const SummerEditImageMobile = "/Assets/SummerEditImageMobile.webp";
export const SerenaBySeaHeroImage = "/Assets/SerenaBySeaHeroImage.webp";
export const BlackCross = "/Assets/BlackCross.svg";
export const SerenaBySeaHeroImageMobile =
  "/Assets/SerenaBySeaHeroImageMobile.webp";
export const WeddingEdit = "/Assets/weddingEdit.webp";
export const WeddingEditMobile = "/Assets/WeddingEditMobile.webp";
export const BasmaCollection = "/Assets/collectionBasma.webp";
export const SerenaCollection = "/Assets/collectionSerena.webp";
export const ShopAllCollection = "/Assets/collectionShopAll.webp";
export const TamaraCollection = "/Assets/collectionTamara.webp";
export const JessieCollection = "/Assets/JessieCollection.webp";
export const SerenaBySeaCollection = "/Assets/collectionSerenaBySea.webp";
export const DesktopBanner = "/Assets/desktopBanner.webp";
export const MobileBanner = "/Assets/mobileBanner.webp";
export const promoSuccessIcon = "/Assets/promoSuccess.svg";
export const BellIconBlack = "/Assets/BellIconBlack.svg";
export const BellIconWhite = "/Assets/BellIconWhite.svg";
export const NewCrossIcon = "/Assets/newCross.svg";
export const WhiteArrow = "/Assets/whiteArrow.svg";
export const LeftArrow = "/Assets/leftArrow.svg";
export const WinterIcon = "/Assets/winter.svg";
export const SummerIcon = "/Assets/summer.svg";
export const SpringIcon = "/Assets/spring.svg";
export const ShoeIcon = "/Assets/shoe.svg";
export const DressIcon = "/Assets/dress.svg";
export const CostPerWearIcon = "/Assets/costPerWear.svg";
export const AutumnIcon = "/Assets/autumn.svg";
export const AccessoryIcon = "/Assets/accessory.svg";
export const BundlesLandingPageHeroBanner =
  "/Assets/BundlesLandingPageHeroBanner.webp";
export const BundlesLandingPageHeroBannerMobile =
  "/Assets/BundlesLandingPageHeroBannerMobile.webp";
export const JessieBlueLogo = "/Assets/JessieBlueLogo.svg";
export const BundleThreeCardImage = "/Assets/BundleThreeCardImage.webp";
export const BundleThreeCardImageMobile =
  "/Assets/BundleThreeCardImageMobile.webp";
export const BundleThreeMainImage = "/Assets/BundleThreeMainImage.webp";
export const BundleThreeMainImageMobile =
  "/Assets/BundleThreeMainImageMobile.webp";
export const BundleTwoCardImage = "/Assets/BundleTwoCardImage.webp";
export const BundleTwoCardImageMobile = "/Assets/BundleTwoCardImageMobile.webp";
export const BundleTwoMainImage = "/Assets/BundleTwoMainImage.webp";
export const BundleTwoMainImageMobile = "/Assets/BundleTwoMainImageMobile.webp";
export const BundleOneCardImage = "/Assets/BundleOneCardImage.webp";
export const BundleOneCardImageMobile = "/Assets/BundleOneCardImageMobile.webp";
export const BundleOneMainImage = "/Assets/BundleOneMainImage.webp";
export const BundleOneMainImageMobile = "/Assets/BundleOneMainImageMobile.webp";
export const desktopVideo =
  "https://mkt-production.s3.me-south-1.amazonaws.com/big-com-items/youtube/hls_out.m3u8";
export const mobileVideo =
  "https://mkt-production.s3.me-south-1.amazonaws.com/big-com-items/reel/hls_out.m3u8";
export const OldMoneyBannerDesktop = "/Assets/OldMoneyBannerDesktop.webp";
export const OldMoneyBannerMobile = "/Assets/OldMoneyBannerMobile.webp";
export const OldMoneyLogo = "/Assets/OldMoneyLogo.png";
export const OldMoneyEditCollection = "/Assets/OldMoneyEditCollection.webp";
export const BasmaCategoryHeroBannerDesktop =
  "/Assets/CategoryHeroPictures/BasmaCategoryHeroBannerDesktop.webp";
export const JessieHeroBannerDesktop =
  "/Assets/CategoryHeroPictures/JessieHeroBannerDesktop.webp";
export const JessieHeroBannerMobile =
  "/Assets/CategoryHeroPictures/JessieHeroBannerMobile.webp";
export const SerenaByTheSeaCategoryHeroBannerDesktop =
  "/Assets/CategoryHeroPictures/SerenaByTheSeaCategoryHeroBannerDesktop.webp";
export const SerenaCategoryHeroBannerDesktop =
  "/Assets/CategoryHeroPictures/SerenaCategoryHeroBannerDesktop.webp";
export const SerenaCategoryHeroBannerMobile =
  "/Assets/CategoryHeroPictures/SerenaCategoryHeroBannerMobile.webp";
export const TamaraSmoothSailingCategoryHeroBannerDesktop =
  "/Assets/CategoryHeroPictures/TamaraSmoothSailingCategoryHeroBannerDesktop.webp";

export const CorporateEditCard = "/Assets/corporate_edit_card.webp";
export const AllEditsCard = "/Assets/AllEditsCard.webp";
export const CreativeEditCard = "/Assets/CreativeEditCard.webp";
export const EducationEditCard = "/Assets/EducationEditCard.webp";
export const EntrepreneurialEditCard = "/Assets/EntrepreneurialEditCard.webp";
export const EventPRManagementEditCard =
  "/Assets/EventPRManagementEditCard.webp";

export const CoverCorporateEditDesktop =
  "/Assets/WorkEdits/CoverCorporateEditDesktop.webp";
export const CoverCorporateEditMobile =
  "/Assets/WorkEdits/CoverCorporateEditMobile.webp";
export const CorporateEditFilter = "/Assets/WorkEdits/CorporateEditFilter.png";
export const CoverCreativeEditDesktop =
  "/Assets/WorkEdits/CoverCreativeEditDesktop.webp";
export const CoverCreativeEditMobile =
  "/Assets/WorkEdits/CoverCreativeEditMobile.webp";
export const CreativeEditFilter = "/Assets/WorkEdits/CreativeEditFilter.png";
export const CoverEducationEditDesktop =
  "/Assets/WorkEdits/CoverEducationEditDesktop.webp";
export const CoverEducationEditMobile =
  "/Assets/WorkEdits/CoverEducationEditMobile.webp";
export const EducationEditFilter = "/Assets/WorkEdits/EducationEditFilter.png";
export const CoverEntrepreneurialEditDesktop =
  "/Assets/WorkEdits/CoverEntrepreneurialEditDesktop.webp";
export const CoverEntrepreneurialEditMobile =
  "/Assets/WorkEdits/CoverEntrepreneurialEditMobile.webp";
export const EntrepreneurialEditFilter =
  "/Assets/WorkEdits/EntrepreneurialEditFilter.png";
export const CoverManagementEditDesktop =
  "/Assets/WorkEdits/CoverManagementEditDesktop.webp";
export const CoverManagementEditMobile =
  "/Assets/WorkEdits/CoverManagementEditMobile.webp";
export const ManagementEditFilter =
  "/Assets/WorkEdits/ManagementEditFilter.png";
export const CoverAllEditDesktop = "/Assets/WorkEdits/AllEditDesktop.webp";
export const CoverAllEditMobile = "/Assets/WorkEdits/AllEditMobile.webp";
export const AllEditFilter = "/Assets/WorkEdits/AllEditFilter.png";
export const NewJessieCollectionsImage =
  "/Assets/WorkEdits/WorkEditCollectionsImage.webp";
export const AllEditCollectionsImage = "/Assets/JessieCollectionsImage.webp";
