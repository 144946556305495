import PropTypes from "prop-types";
import {
  fontSize,
  fontSizeLg,
  fontSizeSm,
  fontType,
  textAlign,
  textColors,
  textDecoration,
  fontStyleArr,
} from "./fontConversion";

const Heading = ({
  headingType = "h1",
  text,
  fontWeight,
  size,
  color,
  align,
  decoration,
  customStyle,
  fontStyle,
  id,
}) => {
  const className = `  ${color ? textColors[color] : "text-black"} ${
    fontType[fontWeight]
  } ${fontSizeLg[size]}  ${fontSize[size]} ${fontSizeSm[size]} ${
    textAlign[align]
  } ${textDecoration[decoration]} ${fontStyleArr[fontStyle]} ${customStyle} `;
  switch (headingType) {
    case "h1":
      return (
        <h1 className={className} id={id}>
          {text}
        </h1>
      );
    case "h2":
      return (
        <h2 className={className} id={id}>
          {text}
        </h2>
      );
    case "h3":
      return (
        <h3 className={className} id={id}>
          {text}
        </h3>
      );
    case "h4":
      return (
        <h4 className={className} id={id}>
          {text}
        </h4>
      );
    case "h5":
      return (
        <h5 className={className} id={id}>
          {text}
        </h5>
      );
    case "h6":
      return (
        <h6 className={className} id={id}>
          {text}
        </h6>
      );
  }
};
Heading.propTypes = {
  headingType: PropTypes.string,
  text: PropTypes.any,
  size: PropTypes.string || PropTypes.node,
  children: PropTypes.array,
  fontWeight: PropTypes.string,
};

export default Heading;
