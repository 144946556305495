import PropTypes from "prop-types";
import {
  fontSize,
  fontSizeLg,
  fontSizeSm,
  fontType,
  textAlign,
  textColors,
  textDecoration,
  fontStyleArr,
} from "./fontConversion";

const TypographyMontserrat = ({
  text,
  fontWeight,
  size,
  color,
  align,
  decoration,
  customStyle,
  fontStyle,
  id,
}) => {
  const className = `  ${color ? textColors[color] : "text-black"} ${
    fontType[fontWeight]
  } ${fontSizeLg[size]}  ${fontSize[size]} ${fontSizeSm[size]} ${
    textAlign[align]
  } ${textDecoration[decoration]} ${fontStyleArr[fontStyle]} ${customStyle} `;

  return (
    <p className={className} id={id}>
      {text}
    </p>
  );
};
TypographyMontserrat.propTypes = {
  text: PropTypes.any,
  size: PropTypes.string || PropTypes.node,
  children: PropTypes.array,
  fontWeight: PropTypes.string,
};

export default TypographyMontserrat;
